import React, { useState,useEffect } from 'react'
import {
    MRT_GlobalFilterTextField,
    MRT_TableBodyCellValue,
    MRT_TablePagination,
    MRT_ToolbarAlertBanner,
    flexRender,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import EditItem from './EditItem'
import { Link } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux';
import style from '../../Styles/dashboard.module.css'
import noImg from '../../assets/images/No_Image.png'
import Environment from '../../config';

const TableItem = () => {
    const [data,setItems] = useState([])
    const { token } = useSelector((state) => state.user);

    useEffect(() => {
        axios.get(`${Environment.baseURL}/api/admin/ImageDetails/getItems`, { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => {
                setItems(response.data.data)
                console.log(response.data)

            }).catch((err) => { console.log(err.response) })
        
    }, [])
    const columns = [
        {
            accessorKey: 'itemCode',
            header: 'item Code',
        },
        {
            accessorKey: 'itemName',
            header: 'Item name',
        }, {
            accessorKey: 'price',
            header: 'Price',
        },
        {
            accessorKey: 'Image',
            header: 'Image',
            columnDefType: 'display',
            enableColumnOrdering: 1,
            Cell: ({ renderedCellValue, row, cell }) => (
                <>
                    <div>
                    {row.original.mainPic?
<img alt='' src={row.original.mainPic} className={`${style.item__img}`}/>
                    :
                    <img alt='' src={noImg} className={`${style.item__img}`}/>

        }
                    </div>
                </>
            ),
        },
        {
            accessorKey: 'Action',
            header: 'Action',
            columnDefType: 'display',
            enableColumnOrdering: 1,
            Cell: ({ renderedCellValue, row, cell }) => (
                <>
                    <div>
                        <Link to={`/edit-item/${row.original.itemCode}`}>More Details</Link>
                    </div>
                </>
            ),
        },

    ];
    const table = useMaterialReactTable({
        columns,
        data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        enableRowSelection: false,
        initialState: {
            pagination: { pageSize: 20, pageIndex: 0 },
            showGlobalFilter: true,
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
    });

    return (
        <Stack sx={{ m: ' 0' }}>
            <Typography variant="h4">Items details</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {/**
         * Use MRT components along side your own markup.
         * They just need the `table` instance passed as a prop to work!
         */}
                <MRT_GlobalFilterTextField table={table} />
                <MRT_TablePagination table={table} />
            </Box>
            {/* Using Vanilla Material-UI Table components here */}
            <TableContainer>
                <Table>
                    {/* Use your own markup, customize however you want using the power of TanStack Table */}
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableCell align="center" variant="head" key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.Header ??
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map((row, rowIndex) => (
                            <TableRow key={row.id} selected={row.getIsSelected()}>
                                {row.getVisibleCells().map((cell, _columnIndex) => (
                                    <TableCell align="center" variant="body" key={cell.id}>
                                        {/* Use MRT's cell renderer that provides better logic than flexRender */}
                                        <MRT_TableBodyCellValue
                                            cell={cell}
                                            table={table}
                                            staticRowIndex={rowIndex} //just for batch row selection to work
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
        </Stack>
    );
}

export default TableItem
