import { useContext, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Component/Dashboard/Dashboard';
import Nav from './Layout/Nav';
import { useDispatch } from 'react-redux';
import { login } from './Redux/slices/UserSlice';
import EditItem from './Component/Dashboard/EditItem';
import { MantineProvider, Button } from '@mantine/core';
import Login from './Component/Login/Login'
import 'react-toastify/dist/ReactToastify.css';
import Protectpages from './Component/ProtectPage'
import Category from './Component/Category/Category';
import SubCategory from './Component/SubCategory/SubCategory';
import Color from './Component/Color/Color';
import Banner from './Component/Banner/Banner';
import HomeVideo from './Component/HomeVideo/HomeVideo';
import Notification from './Component/Notification/Notification';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      try {
        const parsedToken = JSON.parse(usertoken);
        const userName = localStorage.getItem('userName');
        dispatch(login(parsedToken, userName));
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  }, [dispatch]);
  return (
    <MantineProvider>
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
        <Route element={<Protectpages />}>
          <Route path="/" element={<Dashboard />} />
          <Route path='/edit-item/:id' element={<EditItem/>}/>
          <Route path='/category' element={<Category/>}/>
          <Route path='/subCategory/:id' element={<SubCategory/>}/>
          <Route path='/color' element={<Color/>}/>
          <Route path='/banner' element={<Banner/>}/>
          <Route path='/video' element={<HomeVideo/>}/>
          <Route path='/notification' element={<Notification/>}/>

          </Route>
          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
    </MantineProvider>
  );
}

export default App;
